@import '~ethos-design-system/src/components/Media/Media.scss';

$overlay: 120px;
$mobileOverlay: 60px;

.whiteCurveOverlay {
  position: absolute;
  z-index: 1;
  width: $mobileOverlay;
  height: $mobileOverlay;
  top: 0;
  right: 0;
  @include for-laptop-and-up {
    width: $overlay;
    height: $overlay;
  }
  .inset {
    width: $mobileOverlay;
    height: $mobileOverlay;
    overflow: hidden;
    position: relative;
    @include for-laptop-and-up {
      width: $overlay;
      height: $overlay;
    }
    &:before {
      content: '';
      top: 0;
      right: 0;
      box-shadow: 30px -30px 0 0 var(--White);
      display: block;
      width: 200%;
      height: 200%;
      position: absolute;
      border-radius: 50%;
      @include for-laptop-and-up {
        box-shadow: 60px -60px 0 0 var(--White);
      }
    }
  }
}

.heading {
  font-family: 'Cambon';
  font-style: normal;
  font-size: 32px;
  line-height: 140%;
  font-weight: 700;
  color: #054742;
  margin-bottom: var(--Space-40);

  @include for-phone-only {
    padding-top: var(--Space-16);
  }

  @include for-laptop-and-up {
    font-size: 44px;
    line-height: 50px;
    margin-bottom: var(--Space-56);
  }

  strong {
    color: var(--BrandDarkSalamander);
  }

  p {
    margin: 0;
  }
}

.cta {
  display: inline-block;
  background: var(--BrandCypress);
  color: var(--White) !important;
  padding: var(--Space-16) var(--Space-80);
  border-radius: 8px;
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 500;
  font-size: 17.5px;
  line-height: 24px;

  @include for-laptop-and-up {
    padding: var(--Space-16) var(--Space-64);
  }

  &:hover,
  &:active {
    opacity: 0.85;
  }
}

.Label {
  max-width: 780px;
  width: calc(100vw - 40px);
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  margin-bottom: var(--Space-16);
  text-align: start;

  &:not(:first-child) {
    padding-top: var(--Space-24);
    border-top: 1px solid rgba(194, 194, 194, 0.5);
  }
}

.Panel {
  max-width: 780px;
  width: calc(100vw - 40px);
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 400;
  font-size: 17.5px;
  line-height: 24px;
  text-align: start;
}

.Toggle {
  padding-left: 20px;
}

.Markdown {
  a {
    text-decoration: underline;
  }
}

.minimalTheme {
  .heading {
    color: #000;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.96px;
    margin-bottom: 64px;

    @include for-phone-only {
      font-size: 36px;
      line-height: 52px;
      letter-spacing: -0.8px;
      margin-bottom: 48px;
      padding-top: 0;
    }
  }

  .Label {
    color: var(--theme-fg-default, #272727);
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.24px;
  }

  .Markdown {
    color: #525252;
    font-size: 18px;
    line-height: 26px;
  }
}

@import '~ethos-design-system/src/components/Media/Media.scss';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;

  padding: 0px 60px;

  @include for-phone-and-tablet {
    padding: 0px 16px;
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-phone-and-tablet {
      padding: 0 16px;
    }
  }
}

.accordion {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  gap: 24px;

  @include for-phone-and-tablet {
    gap: 12px;
  }

  .accordionTitle {
    color: #272727;
    font-family: NewTheinhardt;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.24px;

    @include for-phone-and-tablet {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .accordionContent {
    color: #272727;
    font-family: NewTheinhardt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    @include for-phone-and-tablet {
      font-size: 17.5px;
      line-height: 24px;
    }
  }
}

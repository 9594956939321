@import '~ethos-design-system/src/components/Media/Media.scss';

.willsFaq {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0px 60px 60px 60px;

  @include for-phone-and-tablet {
    display: block;
    padding: 0px 16px 48px 16px;
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1320px;
    border-radius: 24px;
    display: flex;
    padding: 80px;
    flex-direction: row;
    justify-content: space-between;
    background-image: url('https://res.cloudinary.com/getethos/image/upload/v1724234945/FAQ_section_c6zof0.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include for-phone-and-tablet {
      background-image: linear-gradient(284deg, #f3f7f7 0%, #e6efee 100%);
      flex-direction: column;
      padding: 48px 16px;
    }

    .title {
      color: #056257;
      font-family: NewTheinhardt;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      @include for-phone-and-tablet {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }

    .heading {
      min-width: 310px;
      margin-top: 24px;
      margin-bottom: 40px;
      color: black;
      font-family: NewTheinhardt;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 55px; /* 137.5% */
      letter-spacing: -0.4px;

      @include for-phone-and-tablet {
        margin-top: 12px;
        font-family: Theinhardt;
        font-size: 28px;
        font-weight: 500;
        line-height: 40px; /* 142.857% */
        letter-spacing: -0.28px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.accordion {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  gap: 24px;

  @include for-phone-and-tablet {
    gap: 12px;
  }

  .accordionTitle {
    color: #272727;
    /* Display M Med */
    font-family: NewTheinhardt;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.24px;

    @include for-phone-and-tablet {
      /* Body/Body Bold */
      font-size: 19px;
      line-height: 24px; /* 126.316% */
    }
  }

  .accordionContent {
    color: #272727;
    /* Body L Reg */
    font-family: NewTheinhardt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */

    @include for-phone-and-tablet {
      font-size: 17.5px;
      line-height: 24px;
    }
  }
}
